import React, { useEffect, useState, useRef } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import Portal from "../../Base/Portal";
import { arrowRight } from "../../Base/SVG";
import StakingItem from "./StakingItem";
import { StakingModul } from "./StakingModul";
import Web3 from "web3";

const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect";
const CONNECTED_TEXT = "Connected";

export default function Staking({ setHeaderStyle, setTitle, props }) {
  const money = Intl.NumberFormat("en-US", {});
  const dateTime = new Intl.DateTimeFormat("en-US", {
    dateStyle: "full",
    timeStyle: "long",
  });

  const stakingContractAddress = useRef(
    "0x355a521Fc893F833986fa49225ad66C48E675CA6"
  ); // mainnet
  const mainContractAddress = useRef(
    "0x2FFDFe146994670eE6668E2D8473C85D06A6C6ba"
  ); // mainnet token

  const stakingContractABI = useRef([
    {
      inputs: [
        { internalType: "address", name: "token_", type: "address" },
        { internalType: "uint256", name: "tiersCount", type: "uint256" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "OwnableInvalidOwner",
      type: "error",
    },
    {
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "OwnableUnauthorizedAccount",
      type: "error",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      inputs: [
        { internalType: "uint256", name: "tierDuration_", type: "uint256" },
        {
          components: [
            { internalType: "uint256", name: "apy", type: "uint256" },
            { internalType: "uint256", name: "minAmount", type: "uint256" },
          ],
          internalType: "struct StackingContract.Tier[]",
          name: "tiers_",
          type: "tuple[]",
        },
        { internalType: "uint256", name: "maxStakingAmount_", type: "uint256" },
      ],
      name: "configure",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "maxStakingAmount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "staker", type: "address" }],
      name: "reward",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "staker", type: "address" }],
      name: "rewardIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "stake",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "stakes",
      outputs: [
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "uint256", name: "tierDuration", type: "uint256" },
        { internalType: "uint256", name: "stakedAt", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "start",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "started",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "tierDuration",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "tiers",
      outputs: [
        { internalType: "uint256", name: "apy", type: "uint256" },
        { internalType: "uint256", name: "minAmount", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "token",
      outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalRewardsAmount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalStackingAmount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "unstake",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "token_", type: "address" },
      ],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ]);

  const mainContractABI = useRef([
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "subtractedValue", type: "uint256" },
      ],
      name: "decreaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "faucet",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "addedValue", type: "uint256" },
      ],
      name: "increaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "sender", type: "address" },
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
  ]);

  const web3 = useRef(null);
  const StakingToken = useRef(null);
  const MainToken = useRef(null);
  const decimals = useRef(null);

  const onboarding = useRef();

  const [connectConnectButtonText, setConnectButtonText] =
    useState(ONBOARD_TEXT);
  const [isConnectBtnDisabled, setConnectBtnDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const [ratesPopup, setRatesPopup] = useState(false);
  const [error, setError] = useState("");
  const [chosenAccount, setChosenAccount] = useState("");
  const [chainName, setChainName] = useState("Unknown Network");
  const [errorData, setErrorData] = useState("");
  const [notification, setNotification] = useState("");

  const [rounds, setRounds] = useState([]);
  const [amount, setAmount] = useState("");

  const [tokenSymbol, setTokenSymbol] = useState("");

  const [maxStakingAmount, setMaxStakingAmount] = useState("");
  const [totalStakingAmount, setTotalStakingAmount] = useState("");
  const [totalRewardsAmount, setTotalRewardsAmount] = useState("");
  const [tierDuration, setTierDuration] = useState("");
  const [rewardAmount, setRewardAmount] = useState("");

  const [stakesAmount, setStakesAmount] = useState("");
  const [stakesTierDuration, setStakesTierDuration] = useState("");
  const [stakesStakedAt, setStakesStakedAt] = useState("");

  const [stakeBtnTxt, setStakeBtnTxt] = useState("Stake");
  const [stakeBtnEnabled, setStakeBtnEnabled] = useState(false);

  const [unstakeBtnTxt, setUnstakeBtnTxt] = useState("Unstake");
  const [unstakeBtnEnabled, setUnstakeBtnEnabled] = useState(false);

  const [maxBtnEnabled, setMaxBtnEnabled] = useState(true);

  const tiers = useRef({});

  const showRatesPopup = () => {
    setRatesPopup(true);
  };

  const hideRatesPopup = () => {
    setRatesPopup(false);
  };

  const showErrorMessage = (message, data) => {
    setError(message);
    setErrorData(data.message || data);
  };

  const clearErrorMessage = (message) => {
    setError("");
    setErrorData("");
  };

  const showNotification = (message) => {
    setNotification(message);
  };
  const clearNotification = (message) => {
    setNotification("");
  };

  const enableStakeButton = () => {
    setStakeBtnEnabled(true);
  };

  const disableStakeButton = () => {
    setStakeBtnEnabled(false);
  };

  const enableUnstakeButton = () => {
    setUnstakeBtnEnabled(true);
  };

  const disableUnstakeButton = () => {
    setUnstakeBtnEnabled(false);
  };

  const setStakeButtonOnWait = () => {
    setStakeBtnTxt("Staking...");
  };

  const unsetStakeButtonFromWait = () => {
    setStakeBtnTxt("Stake");
  };

  const setUnstakeButtonOnWait = () => {
    setUnstakeBtnTxt("Unstaking...");
  };

  const unsetUnstakeButtonFromWait = () => {
    setUnstakeBtnTxt("Unstake");
  };

  // const clearErrorMessage = () => {
  //   setError("")
  //   setErrorData("")
  // }

  // const showNotification = (message) => {
  //   setNotification(message)
  // }

  // const clearNotification = () => {
  //   setNotification("")
  // }

  const requestChain = (ethereum) => {
    ethereum
      .request({ method: "eth_chainId" })
      .then((chainId) => {
        if (web3.current.utils.hexToNumber(chainId) == 1) {
          setChainName("Ethereum Mainnet");
        }
        if (web3.current.utils.hexToNumber(chainId) == 56) {
          setChainName("Binance Smart Chain Mainnet");
        }
        if (web3.current.utils.hexToNumber(chainId) == 97) {
          setChainName("Binance Smart Chain Testnet");
        }
      })
      .catch((error) => {
        showErrorMessage(
          "An error occured while requesting information of your chain",
          error.message || error
        );
      });
  };

  const getMaxStakingAmount = () => {
    StakingToken.current.methods
      .maxStakingAmount()
      .call()
      .then((res) => {
        let dec = web3.current.utils.toBN(1000000000);
        setMaxStakingAmount(
          money.format(web3.current.utils.toBN(res).div(dec).toString())
        );
      })
      .catch((error) => {
        console.log(error);
        setMaxStakingAmount(0);
      });
  };

  const getTotalStakingAmount = () => {
    StakingToken.current.methods
      .totalStackingAmount()
      .call()
      .then((res) => {
        let dec = web3.current.utils.toBN(10 ** decimals.current);
        setTotalStakingAmount(
          money.format(web3.current.utils.toBN(res).div(dec).toString())
        );
      })
      .catch((error) => {
        console.log(error);
        setTotalStakingAmount(0);
      });
  };

  const getTotalRewardsAmount = () => {
    StakingToken.current.methods
      .totalRewardsAmount()
      .call()
      .then((res) => {
        let dec = web3.current.utils.toBN(10 ** decimals.current);
        setTotalRewardsAmount(
          money.format(web3.current.utils.toBN(res).div(dec).toString())
        );
      })
      .catch((error) => {
        console.log(error);
        setTotalRewardsAmount(0);
      });
  };

  const getTierDuration = () => {
    StakingToken.current.methods
      .tierDuration()
      .call()
      .then((res) => {
        setTierDuration(res);
      })
      .catch((error) => {
        console.log(error);
        setTierDuration(0);
      });
  };

  const getTiers = () => {
    let dec = web3.current.utils.toBN(1000000000);
    for (let i = 0; i < 9; i++) {
      StakingToken.current.methods
        .tiers(i)
        .call()
        .then((res) => {
          tiers.current[i] = {
            apy: res["apy"],
            minAmount: money.format(
              web3.current.utils.toBN(res["minAmount"]).div(dec).toString()
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getReward = (stakerAddress) => {
    StakingToken.current.methods
      .reward(stakerAddress)
      .call()
      .then((res) => {
        let dec = web3.current.utils.toBN(10 ** decimals.current);
        setRewardAmount(
          money.format(web3.current.utils.toBN(res).div(dec).toString())
        );
      })
      .catch((error) => {
        console.log(error);
        setRewardAmount(0);
      });
  };

  const getStakes = (stakerAddress) => {
    StakingToken.current.methods
      .stakes(stakerAddress)
      .call()
      .then((res) => {
        console.log(res);
        let dec = web3.current.utils.toBN(10 ** decimals.current);

        setStakesAmount(
          money.format(
            web3.current.utils.toBN(res["amount"]).div(dec).toString()
          )
        );
        setStakesTierDuration(res["tierDuration"]);
        setStakesStakedAt(res["stakedAt"]);
      })
      .catch((error) => {
        console.log(error);
        setStakesAmount("—");
        setStakesTierDuration("—");
        setStakesStakedAt("—");
      });
  };

  const onStakeBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    clearNotification();
    clearErrorMessage();
    disableStakeButton();
    setStakeButtonOnWait();

    let tokens = web3.current.utils
      .toBN(amount * 10 ** decimals.current)
      .toString();

    StakingToken.current.methods
      .stake(tokens)
      .send({ from: chosenAccount })
      .then((res) => {
        unsetStakeButtonFromWait();
        enableStakeButton();
        showNotification("Funds were staked successfully!");
        getReward(chosenAccount);
        getStakes(chosenAccount);
      })
      .catch((err) => {
        console.log(err);
        unsetStakeButtonFromWait();
        enableStakeButton();
        showErrorMessage("Failed to stake funds", err);
      })
      .finally(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  };

  const onUnstakeBtnClick = (e, round) => {
    e.preventDefault();
    e.stopPropagation();

    clearNotification();
    clearErrorMessage();
    disableUnstakeButton();
    setUnstakeButtonOnWait();

    StakingToken.current.methods
      .unstake()
      .send({ from: chosenAccount })
      .then((res) => {
        unsetUnstakeButtonFromWait();
        enableUnstakeButton();
        showNotification("Round were unstaked successfully!");
        getReward(chosenAccount);
        getStakes(chosenAccount);
      })
      .catch((err) => {
        console.log(err);
        unsetUnstakeButtonFromWait();
        enableUnstakeButton();
        showErrorMessage("Failed to unstake round", err);
      })
      .finally(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  };

  const onChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const setMaxAmount = () => {
    setMaxBtnEnabled(false);

    MainToken.current.methods
      .balanceOf(chosenAccount)
      .call()
      .then((result) => {
        setMaxBtnEnabled(true);
        let tokens = web3.current.utils.toBN(result);
        let dec = web3.current.utils.toBN(10 ** decimals.current);
        let maxTokens = tokens.div(dec).toString();
        setAmount(maxTokens);
      })
      .catch((err) => {
        setMaxBtnEnabled(true);
        console.log(err);
      });
  };

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setConnectButtonText(CONNECTED_TEXT);
        setConnectBtnDisabled(true);
        onboarding.current.stopOnboarding();
      } else {
        setConnectButtonText(CONNECT_TEXT);
        setConnectBtnDisabled(false);
      }
    }
  }, [accounts]);

  useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      setChosenAccount(newAccounts[0]);

      web3.current = new Web3(window.ethereum);
      StakingToken.current = new web3.current.eth.Contract(
        stakingContractABI.current,
        stakingContractAddress.current
      );
      MainToken.current = new web3.current.eth.Contract(
        mainContractABI.current,
        mainContractAddress.current
      );

      MainToken.current.methods
        .decimals()
        .call()
        .then((result) => {
          decimals.current = 9;
        })
        .catch((err) => {
          console.log(err);
        });

      MainToken.current.methods
        .symbol()
        .call()
        .then((result) => {
          setTokenSymbol(result);
        })
        .catch((err) => {
          console.log(err);
        });

      requestChain(window.ethereum);

      getMaxStakingAmount();
      getTotalStakingAmount();
      getTotalRewardsAmount();
      getTierDuration();

      getReward(newAccounts[0]);
      getStakes(newAccounts[0]);

      getTiers();

      MainToken.current.methods
        .allowance(newAccounts[0], stakingContractAddress.current)
        .call()
        .then((result) => {
          if (result != "0") {
            enableStakeButton();
            enableUnstakeButton();
          } else {
            MainToken.current.methods
              .totalSupply()
              .call()
              .then((totalSupply) => {
                MainToken.current.methods
                  .approve(stakingContractAddress.current, totalSupply)
                  .send({ from: newAccounts[0] })
                  .then((result) => {
                    enableStakeButton();
                    enableUnstakeButton();
                  })
                  .catch((err) => {
                    console.log(err);
                    showErrorMessage("Unable to get permission from user", err);
                  });
              })
              .catch((err) => {
                console.log(err);
                showErrorMessage("Unable to load total supply", err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
          showErrorMessage("Unable to load allowance", err);
        });
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(handleNewAccounts);
      window.ethereum.on("accountsChanged", handleNewAccounts);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
      };
    }
  }, []);

  const onClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current.startOnboarding();
    }
  };

  useEffect(() => {
    setHeaderStyle("others");
    setTitle("Staking");
    return () => {
      setHeaderStyle(null);
      setTitle(null);
    };
  });

  return (
    <Portal>
      <div className="staking wow animate__fadeInUpSm ">
        <div className="staking__inner">
          <h5>Staking Statistics</h5>

          {!ratesPopup && (
            <div>
              {error.length > 0 && (
                <div className="alert error">
                  {error}
                  {errorData.length > 0 && (
                    <div className="extra">{errorData}</div>
                  )}
                </div>
              )}

              {notification.length > 0 && (
                <div className="alert success">{notification}</div>
              )}

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  Current Wallet address:
                </div>

                {!isConnectBtnDisabled && (
                  <button
                    onClick={onClick}
                    className="staking__inner-address-btn button primary"
                  >
                    {connectConnectButtonText}
                  </button>
                )}

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {chosenAccount}
                  </div>
                )}

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-network">
                    {chainName}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">Staking address:</div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {stakingContractAddress.current}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">Tier Duration:</div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {tierDuration} days
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  Max amount that can be staked:
                </div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {maxStakingAmount} {tokenSymbol}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  Total Tokens Staked:
                </div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {totalStakingAmount} {tokenSymbol}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  Total rewards amount:
                </div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {totalRewardsAmount} {tokenSymbol}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  My stakes amount:
                </div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {stakesAmount} {tokenSymbol}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  My stakes tier duration:
                </div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {stakesTierDuration} days
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  My stakes staked at:
                </div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {stakesStakedAt > 0
                      ? dateTime.format(new Date(stakesStakedAt * 1e3))
                      : "—"}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">
                  My reward amount:
                </div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-address-text">
                    {rewardAmount} {tokenSymbol}
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">Stake:</div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-form">
                    <div className="input__outer">
                      <label htmlFor="">Amount</label>
                      <div className="input">
                        <button
                          className="button max"
                          disabled={!maxBtnEnabled}
                          onClick={setMaxAmount}
                        >
                          Maximum
                        </button>
                        <input
                          type="number"
                          placeholder="Amount of Tokens"
                          onChange={onChangeAmount}
                          value={amount}
                        />
                      </div>
                    </div>
                    <button
                      className="button primary"
                      onClick={onStakeBtnClick}
                      disabled={!stakeBtnEnabled}
                    >
                      {stakeBtnTxt} {arrowRight}
                    </button>
                  </div>
                )}
              </div>

              <div className="staking__inner-box">
                <div className="staking__inner-box-title">Unstake:</div>

                {isConnectBtnDisabled && (
                  <div className="staking__inner-form">
                    <button
                      className="button primary"
                      onClick={onUnstakeBtnClick}
                      disabled={!unstakeBtnEnabled}
                    >
                      {unstakeBtnTxt} {arrowRight}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="staking__inner-body">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>tier</th>
                    <th>APY</th>
                    <th>Minimum Amount to Enter</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(tiers.current).map((_, index) => {
                    return (
                      <StakingItem
                        {...tiers.current[index]}
                        id={index}
                        key={index}
                        symbol={tokenSymbol}
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="table__footer"></div>
            </div>
            <div>
              <ul>
                <li>
                  Both 🔸 kBUSD (BSC) and 🔹 KUSDC (ETH) are eligible to stake
                  and earn APY. please make sure you are on a correct network.
                </li>
                <li>
                  🔼 Staking your tokens will allow you to enter a tiered
                  staking pool with 9 levels. Each level will increase the APY
                  you receive on your invested tokens.
                </li>
                <li>
                  🔼 Once you stake your tokens you will be on a 7-day cycle to
                  receive your current tier APY. At the end of those seven days,
                  you can claim all the tokens earned.
                </li>
                <li>
                  🔼 If you choose to withdraw your tokens before the end of
                  your current 7 day cycle you will not be penalized but you
                  will not receive that week’s tokens earned. There is no lock
                  on your tokens.
                </li>
                <li>
                  🔼 Everyone who stakes will begin at tier 9 and work their way
                  up the ladder staking system every 7 days. If your un-stake
                  your tokens there is no penalty. If you opt in to stake again
                  you start over at tier 9.
                </li>
                <li>
                  🔼 If you don't meet the minimum token requirements for the
                  next tier you will be exiting automatically after that current
                  period ends.{" "}
                </li>
                <li>
                  🔼 Investors will still receive all their earned APY rewards
                  but will need to re-stake again if you choose to at tier level
                  9. This also applies to investors that reach tier one and
                  complete it, you will have to re-stake at tier 9.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
