import React, { useEffect } from "react";
import About from "./Sections/About";
import Doc from "./Sections/Doc";
import Intro from "./Sections/Intro";

export default function Home({ setHeaderStyle, setFooter }) {
  useEffect(() => {
    setHeaderStyle("home");
    setFooter(true);
    return () => {
      setHeaderStyle(null);
      setFooter(false);
    };
  }, []);
  return (
    <>
      <Intro />
      <div className="anchor" id="about"></div>
      <About />
      <Doc />
    </>
  );
}
