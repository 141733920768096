import React from "react";

export default function Intro() {
  return (
    <div className="intro">
      
      <div className="auto__container">
        {/* <div className="intro__announcement">
          <br></br>
          <br></br>
          <br></br>
          <h4>Supreme Staking</h4>
          <h4>320%</h4>
          <br></br>
        </div> */}
        
        <div className="intro__inner">
          <div
            className="intro__cat wow animate__fadeIn"
            data-wow-duration=".8s"
            data-wow-delay=".8s"
          >
            <img src={process.env.PUBLIC_URL + "/images/FCK-logo-boombox-pink.svg"} alt="cat" />
          </div>
          <div className="intro__inner-content">
            <h2
              className="wow animate__fadeInUp "
              data-wow-duration=".8s"
              data-wow-delay=".4s"
            >
              <span><a href="https://fatcatkiller.com/staking">Staking up to 320 % APY </a></span>
            </h2>
            <h6><span><a href="https://fatcatkiller.com/staking">Login & Connect with your metamask to stake. </a></span></h6>
            <h6><span><a href="https://fatcatkiller.com/staking">One stake per wallet is allowed each week, while the tier limit is not reached. </a></span></h6>
            <br></br>
            <div
              className="intro__inner-image wow animate__fadeInUpSm"
              data-wow-duration=".8s"
              data-wow-delay=".6s"
            >
              {/* <img
                src={process.env.PUBLIC_URL + "/images/shapeImg/Mask.png"}
                alt="Mask"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
