import React from "react";
import {
  discord,
  instagram,
  reddit,
  telegram,
  tiktok,
  twitter,
  youtube,
} from "./SVG";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="auto__container">
        <div className="footer__inner">
          <div className="footer__inner-row">
            <div className="footer__inner-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logoBlack.svg"}
                alt="logoBlack"
              />
            </div>
            <div className="footer__inner-links">
 
              <div className="social__row">
                <a
                  href="mailto: info@fatcatkiller.com"
                  className="footer__inner-mail"
                  
                >
                  info@fatcatkiller.com
                </a>

                {/* <a
                  href="https://www.instagram.com/fatcatkillercoin/"
                  className="social"
                >
                  {instagram}
                </a> */}
                <a href="https://t.me/FatCatKillerPortal" className="social">
                  {telegram}
                </a>
                {/* <a href="https://discord.gg/vwN7kn9H27" className="social">
                  {discord}
                </a> */}
                <a href="https://twitter.com/Fat_Cat_Killer" className="social">
                  {twitter}
                </a>
                {/* <a href="https://vm.tiktok.com/ZTdmEqj2J/" className="social">
                  {tiktok}
                </a>
                <a
                  href="https://www.reddit.com/r/FatCatKiller/"
                  className="social"
                >
                  {reddit}
                </a> */}
                <a
                  href="https://www.youtube.com/channel/UCTs0O5_wVvuvLjBrIT6ejsw"
                  className="social"
                >
                  {youtube}
                </a>
              </div>
            </div>
            {/* <div className="footer__inner-books">
              <a
                href="https://fatcatkiller.com/wp-content/uploads/2022/05/Audit_Certificate_Fat-Cat-Killerll.png"
                className="footer__inner-book"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/books/Audit_Certificate.png"
                  }
                  alt="Audit_Certificate"
                />
              </a>
              <a
                href="https://fatcatkiller.com/wp-content/uploads/2022/05/Fat-Cat-Killer-Standard-Smart-Contract-Audit.pdf"
                className="footer__inner-book"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/books/Deployed.png"}
                  alt="Deployed"
                />
              </a>
              <a
                href="/images/books/soken-security.png"
                className="footer__inner-book"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/books/soken-security.png"
                  }
                  alt="soken-security"
                />
              </a>
              <a
                href="https://fatcatkiller.com/wp-content/uploads/2022/05/FatCatKiller-Audit-Report-5-25-22.pdf"
                className="footer__inner-book"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/books/FatCatKiller.png"
                  }
                  alt="FatCatKiller"
                />
              </a>
            </div> */}
          </div>
        </div>
        <div className="copyright">
          Copyright © 2022 Fat Cat Killer | <a href="/">All Rights Reserved</a>{" "}
          | <a href="/">Terms & Conditions</a> | <a href="/">Privacy Policy </a>
        </div>
      </div>
    </footer>
  );
}
