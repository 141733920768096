import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./Base/Footer";
import Header from "./Base/Header";
import Home from "./Pages/Home/Home";
import Lottery from "./Pages/Lottery/Lottery";
import Marketing from "./Pages/Marketing/Marketing";
import Staking from "./Pages/Staking/Staking";
import Team from "./Pages/Team/Team";
import WOW from "wowjs";

export default function App() {
  useEffect(() => {
    fadeOutEffect(document.querySelector(".loader"), 2);
    fadeOutEffect(document.querySelector("#preloder"), 2.5, 1.5);
  }, []);

  const fadeOutEffect = (fadeTarget, delay, animDur = 1.1) => {
    document.body.classList.add("active");

    const style = `opacity: 1; transition: opacity ${animDur}s ease;`;
    fadeTarget.style = style;
    setTimeout(() => {
      fadeTarget.style = `opacity: 0; transition: opacity ${animDur}s ease;`;
      setTimeout(() => {
        document.body.classList.remove("active");
        fadeTarget.style = `display: none`;
      }, animDur * 1000);
    }, delay * 1000);
  };
  const [headerStyle, setHeaderStyle] = useState(null);
  const [footer, setFooter] = useState(false);

  const [title, setTitle] = useState(null);
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <>
      <Header title={title} headerStyle={headerStyle} />
      <main className="main">
        <Routes>
          <Route
            path=""
            element={
              <Home setHeaderStyle={setHeaderStyle} setFooter={setFooter} />
            }
          />
          <Route
            path="/team"
            element={
              <Team setHeaderStyle={setHeaderStyle} setTitle={setTitle} />
            }
          />
          <Route
            path="/marketing"
            element={
              <Marketing setHeaderStyle={setHeaderStyle} setTitle={setTitle} />
            }
          />
          <Route
            path="/lottery"
            element={
              <Lottery setHeaderStyle={setHeaderStyle} setTitle={setTitle} />
            }
          />
          <Route
            path="/staking"
            element={
              <Staking setHeaderStyle={setHeaderStyle} setTitle={setTitle} />
            }
          />
        </Routes>
      </main>
      {footer && <Footer />}
      <div id="preloder" ref={null}>
        <div className="loader">
          <img
            src={process.env.PUBLIC_URL + "/favicon/android-chrome-192x192.png"}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
}
