import React, { useEffect, useState, useRef } from "react";

export default function Doc() {

  const [kbusdPrice, setKBUSDPrice] = useState("")
  const [kbusdPercentUp, setKBUSDPercentUp] = useState("")
  const [kbusdTotalSupply, setKBUSDTotalSupply] = useState("")
  const [kbusdTotalMarketCap, setKBUSDTotalMarketCap] = useState("")

  const [kusdcPrice, setKUSDCPrice] = useState("")
  const [kusdcPercentUp, setKUSDCPercentUp] = useState("")
  const [kusdcTotalSupply, setKUSDCTotalSupply] = useState("")
  const [kusdcTotalMarketCap, setKUSDCTotalMarketCap] = useState("")


  useEffect(() => {
    async function fetchKBUSDData() {
      const response = await fetch("https://stats.fatcatkiller.com/api/tokens/kbusd");
      if (!response.ok) {
        return
      }
      let json = await response.json();
      setKBUSDTotalMarketCap(Number.parseFloat(json["total_market_cap"]).toFixed(2))
      setKBUSDTotalSupply(json["total_supply"])
      setKBUSDPrice(json["price"])
      setKBUSDPercentUp(Number.parseFloat(json["percent_up"]).toFixed(2))
    }
    
    async function fetchKUSDCData() {
      const response = await fetch("https://stats.fatcatkiller.com/api/tokens/kusdc");
      if (!response.ok) {
        return
      }
      let json = await response.json();
      setKUSDCTotalMarketCap(Number.parseFloat(json["total_market_cap"]).toFixed(2))
      setKUSDCTotalSupply(json["total_supply"])
      setKUSDCPrice(json["price"])
      setKUSDCPercentUp(Number.parseFloat(json["percent_up"]).toFixed(2))
    }

    fetchKBUSDData();
    fetchKUSDCData();
  }, []);

  const tokensFormatter = new Intl.NumberFormat('en-US');

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 16
  });


  return (
    <div className="doc">
      <div className="auto__container">
        <div className="doc__inner">
          <h2 className=" wow animate__fadeInUp ">
            USE OF <span>kBUSD/kUSDC</span>{" "}
          </h2>
          <div className="doc__inner-row">
            <div
              className="docItem yellow wow animate__fadeIn "
              data-wow-duration=".8s"
              data-wow-delay=".2s"
            >
              <h4><a href="https://fatcatkiller.com/staking">Staking</a></h4>
              <p>
                 Fat Cat Killers unique <a href="https://fatcatkiller.com/staking">Staking</a> contract rewards its holders to use our digital assets to generate a passive income without selling them with an unprecedented APY.
              </p>
              <p>
                We have a unique tiered system that rewards our investors based on the duration of staking with progressively increasing APY. It also allows a flexibility to un-stake at any time without penalties. The mechanism is set to work for years to come.
              </p>
              <div className="docItem__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/shapeImg/staking.png"}
                  alt="staking"
                />
              </div>
            </div>
            <div
              className="docItem purple wow animate__fadeIn "
              data-wow-duration=".8s"
              data-wow-delay=".4s"
            >
              <h4>
                Payment Systems
              </h4>
                <p>
                Currently, our Token is partnered with few different Crypto Payment Systems such as 
                <a href="https://fcfpay.com/"> FCFpay</a>, <a href="https://killerpay.net/">KillerPay</a>, <a href="https://joinembr.com/">Embr</a> and <a href="https://www.simplex.com/">Simplex</a>, more will be added periodically.  
                Fat Cat Killer also offers a “tokens back” reward system for its customers in order to 
                achieve its status as a preferred token of payment.
                </p>
                <p>
                  Our Debit Cards are coming soon! You will be able to use your kBUSD and kUSDC at your favorite merchants locations.
                </p>
                <p>
                  The Fat Cat Killer brand has a strong presence in the entertainment industry. Our network allows us to 
                  always be in the middle of the best parties, desirable festivals and to receive the support from the show business icons.
                </p>
            </div>
            <div
              className="docItem main wow animate__fadeIn "
              data-wow-duration=".8s"
              data-wow-delay=".6s"
            >
              <h4>NFT Platform</h4>
              <p>
                Fat Cat Killer’s catchy and creative brand lends itself for
                seamless integration into the NFT space. Our token is destined for
                heavy involvement in the NFT sphere using its own in-house
                production as well as our partnership with the Startup,{" "}
                <a href="https://duh.art/">Duh!Art.</a> <br /> As part of our marketing
                strategy, it will select the best of the NFT’s produced by our
                fat cat killer community and reward its most creative artists.
                Its partnership with <a href="https://duh.art/">Duh!Art</a> utilizes the best
                platform to share its members’ creativity with the world. ALL
                proceeds received from <a href="https://duh.art/">Duh!Art</a> will be given to
                the artist(s) responsible for its creation.{" "}
                <a href="https://duh.art/">Duh!Art</a> also agreed to use our token as the
                preferred digital currency for their platform.
              </p>

            </div>
            <div
              className="docItem green wow animate__fadeIn "
              data-wow-duration=".8s"
              data-wow-delay=".6s"
            >
              <h4>Gambling & Lottery</h4>
              <p>
               Our <a href="https://fatcatkiller.com/lottery">Lottery System</a> where investors can win a variety of coveted prizes. 
               From an iPad to a luxury watch to increasingly luxurious items, nothing is off the table. Investors 
               must lock their tokens for a short period of time to be eligible and winners are chosen randomly 
               via its verified smart contract algorithm.
              </p>
              <p>
                The <a href="https://fcfcasino.com/">FCF Casino</a> built for the crypto community allows holders to buy game chips with the Fat Cat Killer token. 
                Play various casino games including: Roulette, BackJack, Poker, and more. Join Tournaments and cashout in crypto.
              </p>
            </div>
          </div>
          <div className="anchor" id="stats"></div>

          <div className="doc__stats">
            <h4
              className=" wow animate__fadeInUp "
              data-wow-duration=".8s"
              data-wow-delay=".4s"
            >
              Live Stats
            </h4>
            <div
              className="doc__stats-row wow animate__fadeInUp "
              data-wow-duration=".8s"
              data-wow-delay=".6s"
            >
              <div className="doc__stats-col">
                <h6 className="uniq">KBUSD Price</h6>
                <h6>{formatter.format(kbusdPrice)}</h6>
              </div>
              <div className="doc__stats-col">
                <h6 className="uniq">KBUSD Total Market Cap</h6>
                <h6>{formatter.format(kbusdTotalMarketCap)}</h6>
              </div>
              <div className="doc__stats-col">
                <h6 className="uniq">KBUSD Since Launch</h6>
                <h6>{kbusdPercentUp}%</h6>
              </div>
              <div className="doc__stats-col">
                <h6 className="uniq">KBUSD Total Supply</h6>
                <h6>{tokensFormatter.format(kbusdTotalSupply)}</h6>
              </div>
            </div>

            <div
              className="doc__stats-row wow animate__fadeInUp "
              data-wow-duration=".8s"
              data-wow-delay=".6s"
            >
              <div className="doc__stats-col">
                <h6 className="uniq">KUSDC Price</h6>
                <h6>{formatter.format(kusdcPrice)}</h6>
              </div>
              <div className="doc__stats-col">
                <h6 className="uniq">KUSDC Total Market Cap</h6>
                <h6>{formatter.format(kusdcTotalMarketCap)}</h6>
              </div>
              <div className="doc__stats-col">
                <h6 className="uniq">KUSDC Since Launch</h6>
                <h6>{kusdcPercentUp}%</h6>
              </div>
              <div className="doc__stats-col">
                <h6 className="uniq">KUSDC Total Supply</h6>
                <h6>{tokensFormatter.format(kusdcTotalSupply)}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
