export const StakingModul = [
  {
    id: "1",
    apy: "320.00",
    dApy: "0.8767123287671",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "100,000,000,000",
  },
  {
    id: "2",
    apy: "160.00",
    dApy: "0.4383561643836",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "80,000,000,000",
  },
  {
    id: "3",
    apy: "80.00",
    dApy: "0.2191780821918",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "50,000,000,000",
  },
  {
    id: "4",
    apy: "60.00",
    dApy: "0.1643835616438",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "30,000,000,000",
  },
  {
    id: "5",
    apy: "40.00",
    dApy: "0.1095890410959",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "20,000,000,000",
  },
  {
    id: "6",
    apy: "30.00",
    dApy: "0.0821917808219",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "10,000,000,000",
  },
  {
    id: "7",
    apy: "15.00",
    dApy: "0.0410958904110",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "No Minimum",
  },
  {
    id: "8",
    apy: "10.00",
    dApy: "0.0273972602740",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "No Minimum",
  },
  {
    id: "9",
    apy: "5.00",
    dApy: "0.0136986301370",
    totalStake: "TBD",
    participant: "TBD",
    mAmount: "1,000,000,000,000",
    currentStake: "No Minimum",
  },
];

