import React from "react";

export default function About() {
  return (
    <div className="about">
      <div className="auto__container">
        <div className="about__inner">
          <h2 className=" wow animate__fadeInUp ">
            Life<span>Style</span>{" "}
          </h2>
          <div className="about__inner-row center">
            <div
              className="about__inner-image left  wow animate__fadeIn "
              data-wow-duration=".8s"
              data-wow-delay=".8s"
            >
              <img src={process.env.PUBLIC_URL + "/images/car.png"} alt="car" />
            </div>
            <div className="about__inner-content">
              <h3
                className=" wow animate__fadeInRight "
                data-wow-duration=".8s"
                data-wow-delay=".4s"
              >
                Extreme sports
              </h3>
              <p
                className=" wow animate__fadeInRight "
                data-wow-duration=".8s"
                data-wow-delay=".6s"
              >
                Fat Cat Killer is on the forefront of extreme sports sponsorship. For example, in July we sponsored world record holder, Hank Amos, at the <a href="https://youtube.com/shorts/OUSEqjSRuRs?feature=share">Catalina Ski Race</a>. The Race is the only open ocean water challenge to compete across 62 miles of open ocean. It is highly coveted and followed around the ski racing world. In February 2023, we plan for another big marketing display by sponsoring an extreme aerial stunt. This aerial stunt uses a wingsuit pilot dropping from a performance hand-glider : both are awash in Fat Cat Killer branding. The stunt will be captured by video using an aerial drone capable of matching the speed of the wingsuit pilot in excess of 120 mph. Our creative participation in the world of extreme sports brings a unique awareness to the adrenaline pumping brand we represent. Extreme events require a lot of strategic planning, as lives are at stake. More events are in the planning stage, and will be released as contracts are executed between the parties.
              </p>
            </div>
          </div>
          <div className="about__inner-row center">
            <div className="about__inner-content">
              <h3
                className=" wow animate__fadeInLeft "
                data-wow-duration=".8s"
                data-wow-delay=".4s"
              >
                Charity
              </h3>
              <p
                className=" wow animate__fadeInLeft "
                data-wow-duration=".8s"
                data-wow-delay=".6s"
              >
                Fat Cat Killer has a robust charitable mission. Although We
                are not a charity Token, it’s testing the hypothesis that crypto
                can give opportunity to those afflicted by income inequality. To
                do this, it partnered with{" "}
                <a href="https://www.globalempowermentmission.org/">Global Empowerment Mission,</a> a 501(c)(3)
                non-profit organization founded in 2011 that has supported 295
                missions in 28 countries, all 50 US states, and territories
                including the Caribbean. <br /> We chose{" "}
                <a href="https://www.globalempowermentmission.org/">Global Empowerment Mission </a> as a third party
                administrator and auditor of its donation fund for many reasons,
                one of which is they have <a href="https://www.guidestar.org/">Guidestar’s</a> Platinum
                Seal of Transparency. Crypto investors can feel confident their
                donations are held accountable to fulfill their charitable
                mission(s).
              </p>
            </div>
            <div className="about__inner-videos">
              <div className="about__inner-bubble">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/bubble-2.svg"}
                  alt="bubble"
                />
              </div>
              <div
                className="video__outer wow animate__fadeInRight "
                data-wow-duration=".8s"
                data-wow-delay=".4s"
              >
                {/* <div className="video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/D2_Xq35Ak-w"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </div> */}
              </div>
              <div
                className="video__outer wow animate__fadeInRight "
                data-wow-duration=".8s"
                data-wow-delay=".6s"
              >
                <div className="video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/D2_Xq35Ak-w"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="about__inner-row end">
            <div
              className="about__inner-image rad wow animate__fadeIn "
              data-wow-duration=".8s"
              data-wow-delay=".4s"
            >
              <a href="http://game.fatcatkiller.com/" target="_blank">
                <img
                  src={process.env.PUBLIC_URL + "/images/gaming.jpg"}
                  alt="gaming"
                />
              </a>
              <span className="bubble-3">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/icons/gamingBubble.svg"
                  }
                  alt="bubble"
                />
              </span>
            </div>
            <div className="about__inner-content">
              <h3
                className=" wow animate__fadeInRight "
                data-wow-duration=".8s"
                data-wow-delay=".4s"
              >
                Gaming
              </h3>
              <p
                className=" wow animate__fadeInRight "
                data-wow-duration=".8s"
                data-wow-delay=".6s"
              >
                Gaming is an integral part of entertainment and we have published a fun <a href="http://game.fatcatkiller.com/">Browser Game</a> on our website that you can enjoy right now. 
                We are always expanding our reach through our partnerships. At this time we are discussing a partnership with <a href="https://yohunt.io/">YoHunt</a> team to take part in 
                their "Season Two".
              </p>
            </div>
          </div>
          <div className="about__inner-row center">
            <div className="about__inner-content">
              <h3
                className=" wow animate__fadeInLeft "
                data-wow-duration=".8s"
                data-wow-delay=".4s"
              >
                Music & Entertainment
              </h3>
              <p
                className=" wow animate__fadeInLeft "
                data-wow-duration=".8s"
                data-wow-delay=".6s"
              >
                Fat Cat Killer gives access to its own homegrown variety of 
                Artists into its Multimedia brand. We made a unique Music <a href="https://youtu.be/0dCC1CCuFK4">Video</a> in Medellin, 
                which is available on our YouTube channel.
                <br>
                 
                </br>
                We also have songs from various artists on other channels like Spotify etc. Multiple artists from around the 
                world already have embraced the Fat Cat Killer brand, such as legendary Grammy Award winning musician  
                <a href="https://youtube.com/shorts/HNZKMmyEvZU?feature=share"> NeYo</a> and TV personality & <a href="https://youtube.com/shorts/HJOB-L_yjGg?feature=share">DJ Blake Horstmann</a>. More songs will be added periodically by the artists that we choose to produce.

              </p>
              <div
                className="about__inner-content-image wow animate__fadeIn "
                data-wow-duration=".8s"
                data-wow-delay=".6s"
              >
                <img
                  src={process.env.PUBLIC_URL + "https://ik.imagekit.io/fck/photo_2022-11-22_09-52-01-removebg-preview.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678211111599"}
                  alt="logo"
                />
              </div>
            </div>
            <div className="about__inner-image rad wow animate__fadeIn ">
              <img
                src={process.env.PUBLIC_URL + "/images/music.png"}
                alt="music"
              />
              <span className="bubble-1">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/icons/musicBubble-1.svg"
                  }
                  alt="bubble"
                />
              </span>
              <span className="bubble-2">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/icons/musicBubble-2.svg"
                  }
                  alt="bubble"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
