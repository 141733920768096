import React, { useEffect } from "react";
import Portal from "../../Base/Portal";
import { arrowRight } from "../../Base/SVG";

export default function Lottery({ setTitle, setHeaderStyle }) {
  useEffect(() => {
    setHeaderStyle("others");
    setTitle("Lottery");
    return () => {
      setHeaderStyle(null);
      setTitle(null);
    };
  }, []);
  return (
    <Portal>
      <div className="lottery wow animate__fadeInUpSm ">
        <div className="lottery__inner">
          <h5>WIN A MACBOOK AIR</h5>
          <h6>LOCKDOWN STARTS IN:</h6>
          <h5 className="uniq">LOTTERY IS OVER</h5>
          <h6>ENTRY'S MADE:</h6>
          <div className="lottery__number">69</div>
          <button className="button primary">Enter lottery {arrowRight}</button>
        </div>
      </div>
    </Portal>
  );
}
