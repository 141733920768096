import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { arrowRight } from "./SVG";

export default function Header({ headerStyle, title }) {
  // link close function
  var link = document.querySelectorAll(".nav__inner-link");
  link.forEach((e) => {
    onLinkClick(e);
  });
  function onLinkClick(linkItem) {
    linkItem.addEventListener("click", function () {
      setMenu(false);
      document.body.classList.remove("active");
    });
  }
  // menu function
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  useEffect(() => {
    if (menu) {
      document.documentElement.classList.add("active");
    } else {
      document.documentElement.classList.remove("active");
    }
  }, [menu]);
  const close = () => {
    setMenu(false);
    document.body.classList.remove("active");
  };
  // sticky header

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);
  const start = 150;
  const onScroll = () => {
    if (window.scrollY > start) {
      document.getElementById("header").classList.add("sticky");
    } else {
      document.getElementById("header").classList.remove("sticky");
    }
  };
  return (
    <header className="header" id="header">
      <div className="auto__container">
        <div className="header__inner">
          <Link
            to="/"
            className="header__inner-logo"
            onClick={() => {
              setMenu(false);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/logoFull.svg"}
              alt="logoFull"
            />
            {title && (
              <span className="animate__animated animate__fadeInLeft animate__delay-1s">
                {title} portal
              </span>
            )}
          </Link>
          <nav className={"nav " + (menu ? "active" : "")} onClick={close}>
            <div className="nav__inner">
              {headerStyle === "home" && (
                <>
                  <a href="https://ik.imagekit.io/fck/WHITE-PAPER.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1670953579151" className="nav__inner-link">
                    White Paper
                  </a>
                  <a href="https://app.solidproof.io/projects/fat-cat-killer" className="nav__inner-link">
                    Audit
                  </a>
                  {/* <div className="nav__dropdown">
                    <div className="nav__dropdown-title">Documents</div>
                    <div className="nav__dropdown-links">
                      <a
                        href="https://fatcatkiller.com/wp-content/uploads/2022/10/WHITE-PAPER-ENG-2022-10-05.pdf"
                        className="nav__inner-link"
                      >
                        White Paper
                      </a>
                      <a href="#" className="nav__inner-link">
                        Audit
                      </a>
                      <a href="#" className="nav__inner-link">
                        KYC
                      </a>
                    </div>
                  </div> */}
                  <a href="#stats" className="nav__inner-link">
                    Stats
                  </a>
                </>
              )}
              {headerStyle === "others" && (
                <>
                  <NavLink to="/team" className="nav__inner-link dark">
                    Team
                  </NavLink>
                  <NavLink to="/marketing" className="nav__inner-link dark">
                    Marketing
                  </NavLink>
                  <NavLink to="/lottery" className="nav__inner-link dark">
                    Lottery
                  </NavLink>
                  <NavLink to="/staking" className="nav__inner-link dark">
                    Staking
                  </NavLink>
                </>
              )}
              {headerStyle === "home" && (
                <div className="menu">
                  <div className="menu__inner">
                    <a href="https://pancakeswap.finance/swap?outputCurrency=0x2FFDFe146994670eE6668E2D8473C85D06A6C6ba" className="nav__inner-link">
                      Buy kBUSD on Pancakeswap
                    </a>
                    <a href="https://www.lbank.info/" className="nav__inner-link">
                      LBank Coming Soon 
                    </a>
                    <a href="https://app.uniswap.org/#/swap?outputCurrency=0x2FFDFe146994670eE6668E2D8473C85D06A6C6ba" className="nav__inner-link">
                      Buy kUSDC on Uniswap
                    </a>
                    <a href="https://www.bitrue.com/trade/killer_usdt" className="nav__inner-link">
                      Bitrue Coming Soon 
                    </a>
                  </div>
                </div>
              )}

              <Link to="/team" className="button log">
                Log In
                {arrowRight}
              </Link>
            </div>
          </nav>
          <div
            className={"burger " + (menu ? "active" : "")}
            id="menuBtn"
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
}
