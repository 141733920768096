import React from "react";

export default function StakingItem(props) {
  console.log(props);
  return (
    <tr>
      <td>{props.id}</td>
      <td>{props.apy}%</td>
      <td>
        {props.minAmount} {props.symbol}
      </td>
    </tr>
  );
}
