import React from "react";

export default function Portal({ children }) {
  return (
    <div className="portal">
      <div className="portal__cat">
        <img src={process.env.PUBLIC_URL + "/images/cat.svg"} alt="cat" />
      </div>
      <div className="auto__container">
        <div className="portal__inner">{children}</div>
      </div>
    </div>
  );
}
